<template>
  <div ref="nodeRef" class="container relative my-14 lg:my-24">
    <div v-if="data.tagline" class="relative mb-10 text-sm-semibold lg:mb-20 lg:text-xl-semibold">
      <span class="absolute top-[9px] left-0 h-0.5 w-full bg-gray1 lg:top-[13px]"></span>
      <span class="relative bg-white pr-4 lg:pr-16">{{ data.tagline }}</span>
    </div>
    <Heading :content="data.title" class="mx-auto !mt-20 !mb-14 max-w-4xl text-center lg:!my-20" />
    <div class="min-h-[500px]">
      <Transition name="fade">
        <LazyTabs v-if="isSectionVisible" :data="data.tabs" />
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const nodeRef: Ref<HTMLDivElement | null> = ref(null)
const isSectionVisible = ref(false)

const { stop } = useIntersectionObserver(
  nodeRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      isSectionVisible.value = true
    }
  },
  {
    threshold: 0
  }
)

onUnmounted(() => {
  stop()
})
</script>
